import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "gatsby";

import GlobalContext from "../../../context/GlobalContext";
import Offcanvas from "../../Offcanvas";
import { Button } from "../../Core";
import NestedMenu from "../NestedMenu";
import { device } from "../../../utils";
import Logo from "../../Logo";
import { menuItems } from "../MainMenu/menuItems";
import { navigate } from "gatsby";
import { isMobile } from "react-device-detect";
import { Location } from '@reach/router';
import { BsList, BsChevronDown } from "react-icons/bs";
import { FiX } from "react-icons/fi";
import MainMenu from "../MainMenu";


const SiteHeader = styled.header`
  background-color: #08131e;
  position: fixed;
  padding: 10px 0px;
  width: 100%;
  z-index: 90;
  top: 0px;
  left: 0px;
  transition-duration: 0.5s;
  .logo {
    filter: invert(1);
  }
`;

const ToggleButton = styled.button`
  color: red;
  border-color: red;
`;

const CloseSideMenu = styled.div`
  position: absolute;
  color: #000000;
  top: 36px;
  right: 20px;
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 33px;
  cursor: pointer;
  border-radius: 100px;
  border: 2px solid #000000;
  text-align: center;
`;

const Header = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <SiteHeader id="header">
        <Container>
          <nav className="navbar site-navbar offcanvas-active navbar-expand-lg navbar-dark">
            {/* <!-- Brand Logo--> */}
            <div className={gContext.headerMinimized ? "logo-minimized brand-logo" : "brand-logo"}>
              <Logo white={false}/>
            </div>
            {
              gContext.headerMinimized ?
                <></> :
                <>
                  <div className="collapse navbar-collapse justify-content-end">
                    <MainMenu items={menuItems} />
                  </div>
                  <div className="header-btns ml-auto ">
                    <Location>
                      {({ location }) => {
                        return (
                          <Button
                          className="medium-button blue-bg sign-up"
                            onClick={() => {
                              gContext.goSetRegistrationData({
                                ...gContext.goGetRegistrationData(),
                                customerType: location.pathname == '/business' ? 'BUSINESS' : 'RESIDENTIAL'
                              })
                              navigate('/check-availability/');
                            }}
                          >
                            Sign up
                          </Button>
                        )
                      }}
                    </Location>
                  </div>
                  <ToggleButton
                    className={`navbar-toggler btn-close-off-canvas ml-3 ${gContext.visibleOffCanvas ? "collapsed" : ""
                      }`}
                    type="button"
                    data-toggle="collapse"
                    data-target="#mobile-menu"
                    aria-controls="mobile-menu"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={gContext.toggleOffCanvas}
                    dark={1}
                  >
                    <BsList />
                  </ToggleButton>
                </>
            }

          </nav>
        </Container>
      </SiteHeader>
      <Offcanvas
        show={gContext.visibleOffCanvas}
        onHideOffcanvas={gContext.toggleOffCanvas}
      >
        <CloseSideMenu 
          onClick={() => {
            if (gContext.visibleOffCanvas) {
              gContext.toggleOffCanvas();
            }
          }}
        ><FiX /></CloseSideMenu>
        <NestedMenu menuItems={menuItems} />
      </Offcanvas>
    </>
  );
};
export default Header;
