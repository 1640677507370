import React, { useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { Button } from "../Core";


const ModalError = () => {
    const gContext = useContext(GlobalContext);

    const handleClose = () => {
        gContext.setAPIErrorMsg({ title: '', message: '' })
    };

    return (
        <Modal
            show={gContext.APIErrorMsg.title !== ''}
            onHide={() => handleClose()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            style={{ borderRadius: '10px' }}
        >
            <Modal.Body className="p-4 text-center">
                <h4 className="pb-4" style={{ color: 'black' }}>{gContext.APIErrorMsg.title}</h4>
                {gContext.APIErrorMsg.message && <p>{gContext.APIErrorMsg.message}</p>}
            </Modal.Body>
            <Modal.Footer className="text-center">
                <Button className="mx-auto" onClick={() => handleClose()}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalError;
