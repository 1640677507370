import React from "react";
import { withTheme } from "styled-components";
import Select from "react-select";

const defaultOptions = [
  { value: "No Values Provided", label: "No Values Provided" },
];

const getCustomStyles = (theme) => {
  return {
    option: (provided, state) => ({
      ...provided,
      fontSize: '1.2em',
      ':hover':{
        backgroundColor: '#f8f8f8'
      }
    }),
    control: (provided, state) => {
      return {
        ...provided,
        border: state.menuIsOpen || state.isFocused,
        borderRadius: 10,
        padding: "1rem 1rem",
        outline: "none",
        boxShadow: "none",
        fontSize: '1.2em'
      };
    },
  };
};

const SelectStyled = ({
  theme,
  options = defaultOptions,
  ...rest
}) => {
  return (
    <Select
      styles={getCustomStyles(theme)}
      options={options}
      {...rest}
    />
  );
};

export default withTheme(SelectStyled);
