import React from "react";
import { graphql, useStaticQuery } from "gatsby";

export const titleCase = str => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(" ");
};


export const getProperty = (object, propKey) => {
    if (object) {
        return object[propKey]
    }
}

export const isContextResidentialOrBusiness = (path) => {
    if (path.includes('business')) return 'business'
    else return 'residential'
}

export const sanitizeTextForLineBreaks = (text) => {
    if (text)
        if (text.indexOf('\n') !== -1)
            return text.split('\n').map((item, key) => (
                <span key={key}>{item}<br /></span>
            ))

        else if (text.indexOf('<br>') !== -1) {
            return text.split('<br>').map((item, key) => (
                <span key={key}>{item}<br /></span>
            ))
        }
        else
            return <span>{text}</span>
}

export const sanitizeTextForPlusBreaks = (text) => {
    if (text)
        if (text.indexOf('+') !== -1)
            return text.split('+').map((item, key) => (
                <>
                    <span className={'mt-2 mb-2' + ' title-span-' + key} key={key}>{key !== 0 ? '+' : ''} {item}</span>
                </>
            ))

        else
            return <span className='mt-2 mb-2'>{text}</span>
}

export const getValueByKey = (arr, propName, propValue) => {
    return arr.find(i => i[propName] === propValue);
}

/**
 * It reorganizes indexed array and returns associative array based on the given key.
 * @param {array} array 
 * @param {string} keyName 
 */
export const getAssociativeArray = (array, keyName) => {
    const tempArr = [];
    array.forEach(element => {
        tempArr[element[keyName]] = element;
    });
    return tempArr;
}

/**
 * It checks existence of UTM params.
 * @param {object} location 
 * @returns object
 */
export const findUtmParams = (location) => {
    const params = new URLSearchParams(location);

    // Medium
    const utmMedium = params.get('utm_medium');
    // Source
    const utmSource = params.get('utm_source');
    // Name
    const utmCampaign = params.get('utm_campaign');
    // Content
    const utmContent = params.get('utm_content');
    // Term
    const utmTerm = params.get('utm_term');

    if (
        (utmMedium !== undefined && utmMedium !== null) ||
        (utmSource !== undefined && utmSource !== null) ||
        (utmCampaign !== undefined && utmCampaign !== null) ||
        (utmContent !== undefined && utmContent !== null) ||
        (utmTerm !== undefined && utmTerm !== null)
    ) {
        return {
            utmMedium,
            utmSource,
            utmCampaign,
            utmContent,
            utmTerm
        };
    } else {
        return false;
    }
}

export const findOfferParams = (location) => {
    const params = new URLSearchParams(location);

    const customerType = params.get('CustomerType');
    const code = params.get('code');

    if (
        customerType !== undefined &&
        customerType !== null &&
        code !== undefined &&
        code !== null
    ) {
        return {
            customerType,
            code
        };
    } else {
        return false;
    }
}

export const findCodeParams = (location) => {
    const params = new URLSearchParams(location);

    const code = params.get('code');

    if (
        code !== undefined &&
        code !== null
    ) {
        return code;
    } else {
        return false;
    }
}

export const GetFeatureGrids = (props) => {
    const data = useStaticQuery(graphql`
        query strapiQuery {
            allStrapiFeatureGrids {
                nodes {
                    page
                    title
                    columns
                    grid_features {
                        icon
                        title
                        description
                    }
                }
            }
        }
    `);

    const allFeatureGrids = getProperty(data, 'allStrapiFeatureGrids');

    if (
        allFeatureGrids['nodes'] !== null &&
        allFeatureGrids['nodes'] !== undefined &&
        allFeatureGrids['nodes'].length > 0
    ) {
        return getValueByKey(allFeatureGrids['nodes'], 'page', props);
    } else {
        return false;
    }
}

export const emailRegex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,8}$/i;

export const homepageCopy = (slug) => {
    const homepageCopies = [
        'sales',
        'janlaunchdm',
        'tci',
        'janlaunch23-e',
        'janlaunch23-t',
        'janlaunch23-fb',
        'janlaunch23-ps',
        'psearchxmas22',
        'wakefieldxmas',
        'gravesendxmas',
        'bostonxmas',
        'e-xmas22',
        't-xmas22',
        'salesxmas22',
        'psocialxmas22',
        'installerdm',
        'darlingtonfc',
        'tci2',
        'kentcricket',
        'spennymoorfc',
        'gforce',
        '25deal',
        'goldenticket',
        'spotify'
    ];

    return (slug == undefined || homepageCopies.includes(slug)) ? true : false
}

export const updateQueryParam = (paramName, paramValue) => {
    if (typeof window !== undefined) {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.set(paramName, paramValue);
        window.history.pushState({ query: queryParams.toString() }, '', `?${queryParams.toString()}`);
    }
};